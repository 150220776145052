// import moment from '../utils/moment'

import Vue from 'vue'

const eventBus = new Vue()

const algoColor = '#B35792'

const dataZoom = [
  {
    type: 'inside',
    start: 0,
    end: 100,
    zoomOnMouseWheel: false
  },
  {
    start: 0,
    end: 10,
    handleIcon: 'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
    handleSize: '80%',
    handleStyle: {
      color: '#f5f5f5',
      shadowBlur: 3,
      shadowColor: 'rgba(0, 0, 0, 0.6)',
      shadowOffsetX: 2,
      shadowOffsetY: 2
    }
  }
]

const blues = [
  'rgb(63,224,208)',
  'rgb(126,249,255)',
  'rgb(88,139,174)',
  'rgb(137,207,240)',
  'rgb(70,130,180)',
  'rgb(87,160,211)',
  'rgb(79,151,163)',
  'rgb(0,128,129)',
  'rgb(101,147,245)',
  'rgb(0,142,204)',
  'rgb(0,128,255)'
]

const grid = {
  left: '40',
  right: '40',
  top: '20',
  width: 'auto'
}

const yAxis = {
  type: 'value',
  axisLine: {
    show: false
  },
  axisTick: {
    show: false
  },
  splitNumber: window.innerWidth > 768 ? 10 : 5
}

const tooltip = {
  triggerOn: 'click',
  position: 'top',
  formatter: (params) => {
    let value
    if (params && params.data && params.data.length) {
      eventBus.$emit('tooltipOpened', params)
      const data = params.data[2]
      value = Object.keys(data.alertData).reduce((prev, curr) => {
        if (data.alertData[curr].value === -1) return prev
        return `${prev}
        <tr>
          <td class="w50p"><span class="text-grey">${curr}</span></td>
          <td class="pl0-3 w50p text-right"><span class="tooltip-point" style="background: ${data.alertData[curr].color}"></span>${data.alertData[curr].value}</td>
        </tr>
        <tr>
          <td class="w50p"><span class="text-grey">Total Feedbacks</span></td>
          <td class="pl0-3 w50p text-right">${data.alertData[curr].totalFeedbacks}</td>
        </tr>`
      }, `<span class="text-violet pa0 ma0">${data.xAxis}</span>`)
    }
    return `<div class="bg-white pa1 box-shadow br1 pb2" style="top: 4.28572px; max-height: 200px; overflow-y: scroll">
              <table>${value}</table>
              <div class="tooltip-circle-down" style="background: ${params.data[2].color};"></div>
            </div>`
  },
  trigger: 'item',
  axisPointer: {
    type: 'cross'
  },
  enterable: true,
  alwaysShowContent: true,
  backgroundColor: '#fff',
  extraCssText: 'box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);text-align:left;padding:0;border-radius: 1rem;margin-top: 5px;',
  textStyle: {
    color: '#000'
  }
}

const brush = {
  xAxisIndex: 'all',
  brushLink: 'all',
  outOfBrush: {
    colorAlpha: 0.1
  }
}

const toolbox = {
  top: -8,
  feature: {
    dataZoom: {
      yAxisIndex: 'none',
      title: {
        zoom: 'Zoom Area',
        back: 'Back'
      }
    },
    brush: {
      type: [''],
      title: {
      }
    }
  },
  tooltip: {
    show: true,
    backgroundColor: '#222',
    formatter: param => param.title,
    textStyle: {
      fontSize: 10
    },
    extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);top: 6.28572px;'
  }
}

const optionsData = {
  xAxisData: [], algoRuleData: [], aiModelData: [], modelList: []
}

const modelColor = {}

const tabLegends = []

const timesData = {
  days: {
    label: 'Days',
    unit: 'hours',
    ms: 24 * 60 * 60 * 1000
  },
  weeks: {
    label: 'Weeks',
    unit: 'hours',
    ms: 7 * 24 * 60 * 60 * 1000
  },
  months: {
    label: 'Months',
    unit: 'hours',
    ms: 30 * 24 * 60 * 60 * 1000
  }
}

const getTabLegend = (color, label, iconType = 'line') => {
  return {
    color,
    label,
    iconType
  }
}

const xAxis = {
  type: 'category',
  // show: !fullView,
  boundaryGap: false,
  axisLine: {
    show: false
  },
  axisTick: {
    show: false
  },
  axisLabel: {
    padding: [2, 0, 0, 0]
  }
}

const generateRandomBlueShade = (modelName) => {
  if (!modelColor[modelName]) {
    modelColor[modelName] = blues[Math.floor(Math.random() * blues.length - 1)]
  }
  return modelColor[modelName]
}

const preparePerformanceOptions = (primaryEngine, data, selectedAlgoRule, activeModel, modelsVisibility) => {
  if (data) {
    const min = 0
    const { xAxisData, algoRuleData, aiModelData, modelList } = optionsData
    const aiModelPoint = {}
    // empty arrays
    xAxisData.length = algoRuleData.length = algoRuleData.length = aiModelData.length = modelList.length = tabLegends.length = 0
    tabLegends.push(getTabLegend(algoColor, selectedAlgoRule))
    tabLegends.push(
      ...(Object.keys(modelsVisibility)
        .filter(modelName => modelsVisibility[modelName])
        .map((modelName, index) => (getTabLegend(generateRandomBlueShade(modelName), modelName, 'dashed')))
      ))
    // for (const [index, item] of data.entries()) {
    //   const xAxis = moment.formatDate(item.datetime * 1000, 'DD MMM, YYYY, hh:mm:ss')
    //   const alertData = {}
    //   optionsData.xAxisData.push(xAxis)
    //   if (item?.algoRule && item?.algoRule?.name !== null) {
    //     alertData[selectedAlgoRule.toUpperCase()] = {
    //       value: item.algoRule.value || 0,
    //       color: algoColor,
    //       totalAccurate: item.algoRule.totalAccurate,
    //       totalFeedbacks: item.algoRule.totalFeedbacks,
    //       totalInaccurate: item.algoRule.totalInaccurate
    //     }
    //   }
    //   optionsData.aiModelData.push(item.aiModel)
    //   for (const model of item.aiModel) {
    //     model.active = model.name === activeModel
    //     aiModelPoint[model.name] = aiModelPoint[model.name] || []
    //     if (index === 0) {
    //       optionsData.modelList.push({
    //         name: model.name,
    //         active: model.active,
    //         color: generateRandomBlueShade(model.name),
    //         visible: modelsVisibility[model.name]
    //       })
    //     }
    //     if (modelsVisibility[model.name] && model.value !== null) {
    //       alertData[model.name.toUpperCase()] = {
    //         value: model.value,
    //         color: generateRandomBlueShade(model.name),
    //         totalAccurate: model.totalAccurate,
    //         totalFeedbacks: model.totalFeedbacks,
    //         totalInaccurate: model.totalInaccurate
    //       }
    //       // AI Tooltip Data
    //       aiModelPoint[model.name].push({ xAxis: xAxis, yAxis: model.value, color: generateRandomBlueShade(model.name), alertData: alertData })
    //     }
    //     // Algo Tooltip Data
    //     if (item?.algoRule) {
    //       optionsData.algoRuleData.push({ xAxis: xAxis, yAxis: item.algoRule.value, color: algoColor, alertData: alertData })
    //     }
    //   }
    // }
    return {
      grid,
      tooltip,
      animation: false,
      brush,
      toolbox,
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          padding: [0, -100, 0, 0]
        },
        data: xAxisData
      },
      yAxis: {
        ...yAxis,
        min
      },
      dataZoom,
      series: prepareSeriesList(algoRuleData, modelList, aiModelPoint, selectedAlgoRule)
    }
  }
}

const prepareSeriesList = (algoRuleData, modelList, aiModelPoint, selectedAlgoRule) => {
  return [
    {
      name: selectedAlgoRule,
      type: 'line',
      lineStyle: {
        color: algoColor,
        // shadowBlur: primaryEngine === 'RE' ? 10 : 0,
        shadowColor: '#777',
        width: 1.5,
        type: 'solid'
      },
      symbol: 'circle',
      symbolSize: 8,
      itemStyle: {
        color: '#B15690'
      },
      data: algoRuleData.map(data => [data.xAxis, data.yAxis, data])
    },
    ...modelList.filter(model => model.visible).map((model) => {
      return {
        name: model.name,
        type: 'line',
        lineStyle: {
          color: model.color,
          width: 1.5,
          shadowColor: '#777',
          type: 'dashed'
        },
        symbol: 'circle',
        symbolSize: 8,
        itemStyle: {
          color: aiModelPoint[model.name][0].alertData[model.name].color
        },
        data: aiModelPoint[model.name].map(data => [data.xAxis, data.yAxis, data])
      }
    })
  ]
}

const constructData = (active, data, selectedAlgoRule, currentSelectedTimeSlot) => {
  const chartData = []
  let newAlgoRule
  if (data?.length) {
    for (const item of data) {
      let algoDetail
      for (const rule of item.algoRule) {
        if (rule.name === selectedAlgoRule) {
          newAlgoRule = rule.name
          algoDetail = { ...rule }
        }
      }
      chartData.push({
        datetime: item.datetime,
        algoList: item.algoRule,
        aiModel: item.aiModel,
        algoRule: algoDetail
      })
      chartData.sort((a, b) => a.datetime - b.datetime)
    }
  }
  return { chartData, newAlgoRule }
}

const prepareChartMetricOptions = (metricsData, xAxisData, currentSelectedTab, modelsVisibility) => {
  const series = []
  for (const key in metricsData) {
    if (key) {
      if (!tabLegends.find(element => element.label === key)) {
        tabLegends.push({
          label: key,
          iconType: 'line',
          color: generateRandomBlueShade(key),
          isVisible: modelsVisibility[key]
        })
      } else {
        tabLegends.splice(tabLegends.findIndex(element => element.label === key), 1, {
          label: key,
          iconType: 'line',
          color: generateRandomBlueShade(key),
          isVisible: modelsVisibility[key]
        })
      }
      if (Object.prototype.hasOwnProperty.call(metricsData, key) && modelsVisibility[key]) {
        const element = metricsData[key]
        const name = key.length > 20 ? key.substring(0, 23) + '... ' : key
        series.push({
          name,
          type: 'line',
          lineStyle: {
            color: generateRandomBlueShade(key),
            width: 1.5,
            shadowColor: '#777',
            type: 'solid'
          },
          symbol: 'circle',
          symbolSize: 8,
          itemStyle: {
            color: generateRandomBlueShade(key)
          },
          data: element[currentSelectedTab].map(value => (value * 100).toFixed(2))
        })
      }
    }
  }

  return {
    grid,
    tooltip: {
      trigger: 'axis',
      extraCssText: `
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        padding:1rem;
        border-radius: 1rem;
        margin-top: 5px;
        color: #000;
        background: #fff;
        text-align: left;
      `
    },
    toolbox,
    dataZoom,
    yAxis: {
      ...yAxis,
      min: 0,
      max: 100,
      axisLabel: {
        formatter: '{value}%'
      }
    },
    xAxis: {
      ...xAxis,
      data: xAxisData
    },
    series
  }
}

export {
  optionsData,
  eventBus,
  tabLegends,
  timesData,
  preparePerformanceOptions,
  constructData,
  generateRandomBlueShade,
  prepareChartMetricOptions
}
