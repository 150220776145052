<template>
  <div class="display-flex flex-column">
    <div class="display-flex justify-around border-bottom border-light-purple f1-5 w100p-mb flex-row flex-1 align-center maxh6">
      <span>{{ title }}</span>
      <bh-button
       btnClass="border-violet br1 text-white bg-violet br0-5 f1-5 pv0-5 ph2"
       @output="toggleDialogBox"
       v-if="title === 'AI Models'"
        >
        <span>Deploy Devices</span>
      </bh-button>
      <span class="f1-5 text-grey" v-if="isPrimary">Primary</span>
      <span v-else class="display-flex border-violet br1 text-violet bg-white f1-5 pv0-5 ph2 pointer" @click="onPrimaryChange(engineName)">
        Make Primary
      </span>
    </div>
    <div class="f2 w100p-mb display-flex flex-1 align-center flex-column flex-row-mb h70vh overflow-auto hauto-mb">
      <div class="w100p pointer" v-for="(data, index) in dataList" :key="`${data.title}-${index}`" v-on="hasRadio ? {} : { click: selectRule.bind(this, data) }">
        <bh-progress-block :selected="selectedBlock === data.name" :data="data" :hasRadio="hasRadio" @radioChange="onRadioChange" @onToggle="onToggle($event)"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ProgressBlock } from '@/components/Performance'
import { deviceTableHeader } from '@/constants/dashboard'

export default {
  name: 'bh-model-list',
  components: {
    'bh-progress-block': ProgressBlock
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    hasRadio: {
      type: Boolean,
      default: false
    },
    isPrimary: {
      type: Boolean,
      default: false
    },
    engineName: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default() {
        return []
      }
    },
    selectedBlock: {
      type: String,
      default: ''
    },
    dialogClosed: true
  },
  data() {
    return {
      showModal: false,
      deviceListHeaders: deviceTableHeader,
      aiDeviceList: [],
      isCheckboxDisabled: false,
      isDeployBtnDisabled: false
    }
  },
  computed: {
    ...mapGetters('performance', ['getDeployDialogStatus'])
  },
  methods: {
    ...mapActions('performance', ['setDeployDialogStatus']),
    toggleDialogBox() {
      this.setDeployDialogStatus(true)
    },
    closeDialogBox() {
      this.dialogOpened = false
    },
    onPrimaryChange(value) {
      this.$emit('primaryChange', value)
    },
    onRadioChange(data) {
      this.$emit('onRadioChange', data)
    },
    selectRule(data) {
      this.selectedBlock = data.name
      this.$emit('onSelect', this.selectedBlock)
    },
    onToggle(event) {
      this.$emit('onToggle', event)
    }
  }
}
</script>
