<template>
  <div class="display-flex flex-column align-start pa1-mb top-page-container mh2" v-if="!loading">
    <div class="display-flex flex-column align-start pb2 pl0-mb">
      <div class="font-bold f2 pb1">Performance Graph</div>
      <div class="font-bold text-grey">Select the rule sets and models to compare Accuracy</div>
    </div>
    <bh-loader v-if="loading"/>
    <div class="display-flex bg-white border-grey h75vh hauto-mb flex-column-mb w100p w100p-mb ma0-mb overflow-hidden" v-else>
      <bh-model-list
        class="flex-1"
        :title="rulesData.title"
        :isPrimary="rulesData.isPrimary"
        :engineName="rulesData.engineName"
        :dataList="rulesData.dataList"
        :selectedBlock="currentSelectedAlgoRule"
        @primaryChange="onPrimaryChange"
        @onSelect="onAlgoRuleSelect($event)"
      />
      <div class="flex-1 w100p-mb border-lr border-light-purple w30p">
        <bh-daterange
          :dateRange="dates"
          :disabled="fetchingData"
          @onDateRangeSelect="onDateRangeSelect"
          @resetDateRange="onResetDateRange"
          range
        />
        <bh-tab :tabs="tabList" @tabChange="tabChange">
          <div v-if="!fetchingData">
            <div class="display-flex flex-column w100p h60vh ph2 pv2 pa1-mb pt0"
            v-if="Object.keys(optionsData[currentSelectedTab]).length">
            <div class="display-flex align-center justify-end mv1">
              <div class="display-flex align-center justify-between mh1 mh1-tb f1-mb" v-for="(legend, key) in tabLegends" :key="key">
                <template v-if="legend.isVisible">
                  <span
                    class="w2 mr-5"
                    :class="legend.iconType === 'line' ? 'line-legend' : 'dashed-legend'"
                    :style="{ 'border-color': legend.color }">
                  </span>
                  <span class="f1-5 capitalize text-elipsis maxw20">{{ legend.label }}</span>
                </template>
              </div>
            </div>
            <bh-chart :options="optionsData[currentSelectedTab]" :refName="currentSelectedTab"/>
          </div>
          <div v-else class="display-flex align-center justify-center w100p h60vh pa2 f2">
             No Data Available
          </div>
          </div>
            <bh-loader v-else />
        </bh-tab>
      </div>
      <bh-model-list
        class="flex-1"
        hasRadio
        :title="modelData.title"
        :isPrimary="modelData.isPrimary"
        :engineName="modelData.engineName"
        :dataList="modelData.dataList"
        @primaryChange="onPrimaryChange"
        @onRadioChange="onRadioChange"
        @onToggle="onToggle($event)"
      />
    </div>
      <!-- ============================= device section ========================================= -->
    <div class="pv2 w100p">
      <bh-device-list :dataList="modelData.dataList" :topTableHeading="topTableHeading"></bh-device-list>
    </div>
  </div>
</template>
<script>
// import { moment } from '@/utils/moment'
import { mapActions, mapGetters } from 'vuex'
import { ModelList } from '@/components/Performance'
import { getActiveEngines, setPrimaryEngine, setActiveModel, getAccuracy, deployAimodel } from '@/services/performance'
import { tabLegends, timesData, generateRandomBlueShade, prepareChartMetricOptions } from '@/services/performance-options'
import DeviceList from '@/components/device/DeviceList'

export default {
  name: 'bh-performance',
  components: {
    'bh-model-list': ModelList,
    'bh-device-list': DeviceList
  },
  data() {
    return {
      saving: false,
      tabLegends,
      timesData,
      primaryEngine: '',
      activeModel: '',
      topTableHeading: 'Device using this models',
      rulesData: {
        title: 'Algo Rule Sets',
        isPrimary: false,
        engineName: 'RE',
        dataList: []
      },
      modelData: {
        title: 'AI Models',
        isPrimary: false,
        engineName: 'AI',
        dataList: []
      },
      tabList: [
        {
          name: 'Accuracy',
          key: 'accuracy',
          tooltipContent: 'Overall accuracy is fraction of total number correctly classified records during the evaluation period.'
        },
        {
          name: 'Sensitivity',
          key: 'sensitivity',
          tooltipContent: 'Recall or Sensitivity is the fraction of the total amount of relevant / true-positives that were identified out of all the positive / high-risk records in our dataset, during the evaluation period.'
        },
        {
          name: 'Specificity',
          key: 'specificity',
          tooltipContent: 'Specificity is the fraction of the total amount of relevant / true-negatives that were identified out of all the negative / low-risk records in our dataset, during the evaluation period.'
        },
        {
          name: 'Precision',
          key: 'precision',
          tooltipContent: 'Precision (also called positive predictive value) is the fraction of relevant / true-positive identifications of high-risk cases among the predicted records.'
        }
      ],
      optionsData: {},
      fetchingData: false,
      selectedTab: {}
    }
  },
  computed: {
    ...mapGetters('performance', ['currentSelectedTab', 'currentSelectedAlgoRule', 'modelsVisibility', 'metricsData', 'dates', 'chartName', 'dateChange']),
    ...mapGetters(['loading']),
    ...mapGetters('configurations', ['rulesets'])
  },
  watch: {
    currentSelectedAlgoRule: {
      async handler(newValue, oldValue) {
        this.setChartName({ chartName: newValue })
      }
    },
    dates: {
      async handler() {
        await this.getDataAndRender()
      }
    },
    chartName: {
      async handler() {
        await this.getDataAndRender()
      }
    }
  },
  beforeMount() {
    this.tabList.forEach(tab => {
      this.optionsData[tab.key] = {}
    })
  },
  async mounted() {
    this.fetchingData = true
    const accuracy = await getAccuracy()
    await this.fetchAndUpdateRulesSets()
    const aiData = await getActiveEngines({ where: { } })
    // removed unnecessary filter and included the check in single map
    this.rulesData.dataList = this.rulesets.map(({ name, state }) => {
      if (state === 'active') {
        this.setCurrentSelectedAlgoRule(name)
        this.setModelsVisibility({ modelName: name, visibility: true })
      }
      return {
        name: name,
        isActive: state === 'active',
        progressColor: state === 'active' ? '#B15690' : '#7857b3',
        progress: accuracy.algoModels[name] ? ((accuracy.algoModels[name]) * 100).toFixed(2) : 'NA',
        selected: false
      }
    })
    this.modelData.dataList = aiData.filter(data => {
      if (data.isActive) {
        this.activeModel = data.modelName
        deployAimodel({ modelName: data.modelName })
        this.setModelsVisibility({ modelName: data.modelName, visibility: data.isActive })
      }
      if (data.engineName === 'RE') {
        this.rulesData.isPrimary = data.isPrimary
        this.modelData.isPrimary = !data.isPrimary
        return false
      }
      return true
    }).map(data => ({
      name: data.modelName,
      isActive: data.isActive,
      progressColor: generateRandomBlueShade(data.modelName),
      progress: accuracy.aiModels[data.modelName] ? ((accuracy.aiModels[data.modelName]) * 100).toFixed(2) : 'NA'
    }))
  },
  methods: {
    ...mapActions(['setLoader', 'setConfirmationModal', 'setSingleProperty']),
    ...mapActions('performance', ['setCurrentSelectedTab', 'setCurrentSelectedAlgoRule', 'fetchAndUpdateChartData', 'setModelsVisibility', 'setChartName', 'setDates', 'setDateChange']),
    ...mapActions('configurations', ['fetchAndUpdateRulesSets']),
    async getDataAndRender() {
      this.fetchingData = true
      for (const modelName in this.modelsVisibility) {
        if (this.modelsVisibility[modelName]) {
          await this.fetchAndUpdateChartData(modelName)
          this.prepareChartOptions(modelName)
        } else {
          this.prepareChartOptions(modelName)
        }
      }
      this.fetchingData = false
      await this.fetchAndUpdateChartData(this.currentSelectedAlgoRule)
      this.prepareChartOptions(this.currentSelectedAlgoRule)
      if (this.dateChange) {
        this.setDateChange({ dateChange: false })
      }
    },
    onRadioChange(data) {
      this.setConfirmationModal({
        title: `Are you sure, you want to switch Primary AI model to ${data.name} model ?`,
        onClick: async (confirmation) => {
          if (!confirmation) return
          try {
            this.saving = true
            await setActiveModel({ name: data.name })
            this.saving = false
            for (const list of this.modelData.dataList) {
              if (list.name !== 'ML05') { // ML05 will always be Activated
                list.isActive = false
              }
            }
            data.isActive = true
            this.setSingleProperty(['toast', { duration: 3000, show: true, message: `Primary AI model updated to ${data.name} model Successfully!` }])
            await this.getDataAndRender()
          } catch (err) {
            this.setSingleProperty(['toast', { duration: 3000, show: true, message: err.message, color: '#F44336' }])
          }
        }
      })
    },
    onPrimaryChange(engineName) {
      const isRuleEngine = engineName === 'RE'
      const toModal = isRuleEngine ? 'Algorithm' : 'AI'
      this.setConfirmationModal({
        title: `Are you sure, you want to switch Primary model to ${toModal} model ?`,
        onClick: async (confirmation) => {
          if (!confirmation) return
          try {
            this.saving = true
            await setPrimaryEngine({ engineName })
            this.rulesData.isPrimary = isRuleEngine
            this.modelData.isPrimary = !isRuleEngine
            this.saving = false
            this.setSingleProperty(['toast', { duration: 3000, show: true, message: `Primary model updated to ${toModal} model Successfully!` }])
            this.prepareChartOptions(true, this.currentSelectedTab)
          } catch (err) {
            this.setSingleProperty(['toast', { duration: 3000, show: true, message: err.message, color: '#F44336' }])
          }
        }
      })
    },
    async prepareChartOptions(chartName) {
      if (this.metricsData[chartName]?.date.length) {
        this.optionsData[this.currentSelectedTab] =
        prepareChartMetricOptions(this.metricsData, this.metricsData[chartName]?.date, this.currentSelectedTab, this.modelsVisibility)
      }
    },
    async tabChange(e) {
      this.setCurrentSelectedTab(this.tabList[e]?.key)
      await this.getDataAndRender()
    },
    onAlgoRuleSelect(selectedRule) {
      this.setCurrentSelectedAlgoRule(selectedRule)
      for (const rule of this.rulesData.dataList) {
        this.setModelsVisibility({ modelName: rule.name, visibility: rule.name === selectedRule })
      }
    },
    async onToggle({ name, value }) {
      if (value) {
        deployAimodel({ modelName: name })
      }
      this.setModelsVisibility({ modelName: name, visibility: value })
      await this.getDataAndRender()
    },
    onDateRangeSelect(dates) {
      this.setDates(dates)
      this.setDateChange({ dateChange: true })
    },
    async onResetDateRange(reset) {
      if (reset) {
        this.setDates([])
        this.setDateChange({ dateChange: true })
        await this.getDataAndRender()
      }
    }
  }
}
</script>
