<template>
  <div class="w100p w98p-mb pa2 minw30"
    :class="{ 'bg-grey': selected, 'border-bottom border-light-purple': !selected, 'border-grey-mb ma1-mb': !hasRadio }">
    <div class="display-flex justify-between align-center mb1-5" v-if="hasRadio">
      <span class="display-flex align-center">
        <bh-icon class="mr1 text-violet" icon="info" />
        <span class="pointer f1-4 capitalize mt0-5">{{ data.name }}</span>
      </span>
      <span>
        <span v-if="!data.isActive" class="display-flex border-violet br1 text-violet bg-white f1-2 pv0-5 ph1 pointer" @click="onRadioChange(data)">
          Activate
        </span>
        <span class="text-activated f1-5 ml1 mt0-5" v-if="data.isActive">Activated</span>
      </span>
    </div>
    <div class="display-flex justify-between f1-4 capitalize maxw18vw" v-else>
      <span>{{ data.name }}</span>
      <span class="text-activated f1-5 ml1" v-if="data.isActive">Activated</span>
    </div>
    <div class="display-flex justify-between w100p">
      <bh-progress-bar :value="data.progress" :progressColor="data.progressColor" />
      <bh-toggle
        class="pointer f1-2 ml6"
        :value="data.isActive"
        v-if="hasRadio"
        @output="onToggle($event, data.name)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'bh-progress-block',
  props: {
    data: {
      type: Object,
      defalut() {
        return {}
      }
    },
    hasRadio: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onRadioChange(data) {
      this.$emit('radioChange', data)
    },
    onToggle(event, selectedLabel) {
      this.$emit('onToggle', {
        name: selectedLabel,
        value: event
      })
    }
  }
}
</script>
